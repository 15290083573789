// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faCaretLeft as fasFaCaretLeft } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight as fasFaCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";

/**
 * Add solid icons
 */
library.add(fasFaCaretLeft, fasFaCaretRight);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
